import { Injectable } from '@angular/core';
import { AccountValidationVerificationCode, MoneyProcessingRequestEnum as MPEnum } from '@nationwide/dgs-angular-billing-common';
import { PaperlessFlow } from '../../billing/payment-preferences/shared/models/paperless-flow.model';
import { PaymentItem } from '../../billing/payment-preferences/shared/models/payment-item.model';
import { BillingAccountItem } from '../../shared/payments/consider-this/save-as-refund-method/models/billing-account-item.model';
import { CCAuthorizationAgreementParams, EFTAuthorizationAgreementParams } from '../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { DistributedDocumentsResponseModel } from '../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { SubmitPaymentResponses } from '../pay-bill-review/pay-bill-review.service';
import { PayBillForm } from '../pay-bill/pay-bill.form';
import { PaymentAmountOption } from './payment-amount-form-controls/payment-amount.component';

@Injectable()
export class PayBillFlow implements PaperlessFlow {
    savePaperlessPreferredEmail: boolean;
    payBillForm: PayBillForm;
    considerThisDisplayable: boolean;
    emailAddress: string;
    selectedPayMethod: PaymentItem;
    isEligibleForEasyPay: boolean;
    isWarnedPolicy: boolean;
    isPaperlessEligible: boolean;
    isReinstatementEligible: boolean;
    minimumPaymentOption: PaymentAmountOption;
    paymentDueDate: string;
    submitPaymentTimestamp: Date;
    oipaPolicyPaymentCapAcheived?: boolean;
    careMattersPolicyPaymentCapAcheived?: boolean;
    nonOipaPolicyPaymentCapAcheived?: boolean;
    agreementStatus: string;
    sourceSystem: string;
    state: string;
    city: string;
    streetLine1: string;
    nextDueDate: Date;
    nextPaymentAmount: string;
    submitPaymentResponses: SubmitPaymentResponses;
    isPaymentCapExceededOnContinue: boolean;
    reinstatePolicyNumber: string;
    displayPaymentDateSection: boolean;
    productName: MPEnum.ProductName;
    isCyberLife: boolean;
    statementAmount: number;
    fullPayAmount: number;
    canMakeDefaultPaymentMethod: boolean;
    masterpassEncryptedData: any;
    roleIdentifier: string;
    billingAccountsForRefund: BillingAccountItem[];
    updateAutoPaymentAttempted: boolean;
    updateAutoPaySuccessful: boolean;
    isScheduledPayment: boolean;

    paymentSuccessfullySubmitted = false;

    payMethodSaveAttempted = false;
    payMethodSaved = false;

    updatePayMethodAttempted = false;
    payMethodUpdated = false;

    ezPayEnrollmentAttempted = false;
    enrolledInEZPay = false;
    billingAccountHolderName: string;

    paperlessEnrollmentAttempted = false;
    savedPaperlessEsddaPrefs = false;
    enrolledInPaperless = false;

    producerNumbers: string[];
    isOipaPolicy: boolean;
    hasCareMatters: boolean;
    isNonOwner: boolean;
    isSplitPartyUser: boolean;
    isQuickPay: boolean;
    isEligibleToSaveAsRefundMethod: boolean;
    paperlessEmail: string;
    displayCreditCardVulPolicyError: boolean;
    vulCreditCardErrorText = 'Credit cards cannot be used for variable life policies. Please use a different payment method.';
    enrollInFutureAutoPay: string;

    contextCallSuccess: boolean;
    contextCacheCallResponse: any;

    // Additional fields for Policy Level Overpayment Allocation
    isInPolicyLevelOverpaymentAllocationFlow: boolean;
    policyNumber: string;
    distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    accountValidationVerificationCode: AccountValidationVerificationCode;
    automaticPaymentsEnrolled: boolean;
    quickPayAutomaticPaymentsEnrolled: boolean;

    isCollectionPayment = false;
    isReinstatementPayment = false;
    collectionPaymentPolicyNumber: string;
    minimumAmountDue: number;
    eligibleToLogBetweenStatements: boolean;
    save(params: Partial<PayBillFlow>): void {
        this.payBillForm = params.payBillForm || this.payBillForm;
        this.state = params.state || this.state;
        this.city = params.city || this.city;
        this.streetLine1 = params.streetLine1 || this.streetLine1;
        this.sourceSystem = params.sourceSystem || this.sourceSystem;
        this.emailAddress = params.emailAddress || this.emailAddress;
        this.selectedPayMethod = params.selectedPayMethod || this.selectedPayMethod;
        this.minimumPaymentOption = params.minimumPaymentOption || this.minimumPaymentOption;
        if (params.paymentDueDate !== undefined && params.paymentDueDate !== null) {
            this.paymentDueDate = params.paymentDueDate;
        }
        if (params.agreementStatus !== undefined && params.agreementStatus !== null) {
            this.agreementStatus = params.agreementStatus;
        }
        this.isEligibleForEasyPay = params.isEligibleForEasyPay || this.isEligibleForEasyPay;
        this.isWarnedPolicy = params.isWarnedPolicy || this.isWarnedPolicy;
        this.enrollInFutureAutoPay = params.enrollInFutureAutoPay || this.enrollInFutureAutoPay;
        this.updateAutoPaymentAttempted = params.updateAutoPaymentAttempted || this.updateAutoPaymentAttempted;
        this.updateAutoPaySuccessful = params.updateAutoPaySuccessful || this.updateAutoPaySuccessful;
        this.isPaperlessEligible = params.isPaperlessEligible || this.isPaperlessEligible;
        this.isReinstatementEligible = params.isReinstatementEligible || this.isReinstatementEligible;
        this.submitPaymentTimestamp = params.submitPaymentTimestamp || this.submitPaymentTimestamp;
        this.oipaPolicyPaymentCapAcheived = params.oipaPolicyPaymentCapAcheived;
        this.careMattersPolicyPaymentCapAcheived = params.careMattersPolicyPaymentCapAcheived;
        this.nonOipaPolicyPaymentCapAcheived = params.nonOipaPolicyPaymentCapAcheived;
        this.nextDueDate = params.nextDueDate || this.nextDueDate;
        this.nextPaymentAmount = params.nextPaymentAmount || this.nextPaymentAmount;
        this.submitPaymentResponses = params.submitPaymentResponses || this.submitPaymentResponses;
        this.isPaymentCapExceededOnContinue = params.isPaymentCapExceededOnContinue || this.isPaymentCapExceededOnContinue;
        this.reinstatePolicyNumber = params.reinstatePolicyNumber ?? this.reinstatePolicyNumber;
        this.displayPaymentDateSection = params.displayPaymentDateSection || this.displayPaymentDateSection;
        this.productName = params.productName || this.productName;
        this.isCyberLife = params.isCyberLife || this.isCyberLife;
        this.statementAmount = params.statementAmount || this.statementAmount;
        this.fullPayAmount = params.fullPayAmount || this.fullPayAmount;
        this.canMakeDefaultPaymentMethod = params.canMakeDefaultPaymentMethod || this.canMakeDefaultPaymentMethod;
        this.masterpassEncryptedData = params.masterpassEncryptedData || this.masterpassEncryptedData;
        this.roleIdentifier = params.roleIdentifier || this.roleIdentifier;
        this.billingAccountsForRefund = params.billingAccountsForRefund || this.billingAccountsForRefund;
        this.isScheduledPayment = params.isScheduledPayment || this.isScheduledPayment;

        this.paymentSuccessfullySubmitted = params.paymentSuccessfullySubmitted || this.paymentSuccessfullySubmitted;

        this.payMethodSaveAttempted = params.payMethodSaveAttempted || this.payMethodSaveAttempted;
        this.payMethodSaved = params.payMethodSaved || this.payMethodSaved;

        this.updatePayMethodAttempted = params.updatePayMethodAttempted || this.updatePayMethodAttempted;
        this.payMethodUpdated = params.payMethodUpdated || this.payMethodUpdated;

        this.ezPayEnrollmentAttempted = params.ezPayEnrollmentAttempted || this.ezPayEnrollmentAttempted;
        this.enrolledInEZPay = params.enrolledInEZPay || this.enrolledInEZPay;

        this.paperlessEnrollmentAttempted = params.paperlessEnrollmentAttempted || this.paperlessEnrollmentAttempted;
        this.savedPaperlessEsddaPrefs = params.savedPaperlessEsddaPrefs || this.savedPaperlessEsddaPrefs;
        this.enrolledInPaperless = params.enrolledInPaperless || this.enrolledInPaperless;

        this.billingAccountHolderName = params.billingAccountHolderName || this.billingAccountHolderName;
        this.producerNumbers = params.producerNumbers || this.producerNumbers;
        this.isOipaPolicy = params.isOipaPolicy || this.isOipaPolicy;
        this.hasCareMatters = params.hasCareMatters || this.hasCareMatters;
        this.isNonOwner = params.isNonOwner || this.isNonOwner;
        this.isSplitPartyUser = params.isSplitPartyUser || this.isSplitPartyUser;
        this.isQuickPay = params.isQuickPay || this.isQuickPay;

        if (params.isEligibleToSaveAsRefundMethod != null) {
            this.isEligibleToSaveAsRefundMethod = params.isEligibleToSaveAsRefundMethod;
        }

        if (params.isEligibleForEasyPay != null || params.isPaperlessEligible != null || this.isEligibleToSaveAsRefundMethod != null || params.payBillForm?.savePaymentMethod || params.automaticPaymentsEnrolled != null) {
            this.considerThisDisplayable = params.isEligibleForEasyPay || params.isPaperlessEligible || this.isEligibleToSaveAsRefundMethod || Boolean(params.payBillForm?.savePaymentMethod || params.automaticPaymentsEnrolled);
        }

        if (params.isCollectionPayment !== null && params.isCollectionPayment !== undefined) {
            this.isCollectionPayment = params.isCollectionPayment;
        }

        this.isReinstatementPayment = params.isReinstatementPayment ?? this.isReinstatementPayment;

        if (params.collectionPaymentPolicyNumber !== null && params.collectionPaymentPolicyNumber !== undefined) {
            this.collectionPaymentPolicyNumber = params.collectionPaymentPolicyNumber;
        }
        this.displayCreditCardVulPolicyError = params.displayCreditCardVulPolicyError ?? this.displayCreditCardVulPolicyError;
        this.vulCreditCardErrorText = params.vulCreditCardErrorText ?? this.vulCreditCardErrorText;

        // Additional fields for Policy Level Overpayment Allocation
        this.isInPolicyLevelOverpaymentAllocationFlow = params.isInPolicyLevelOverpaymentAllocationFlow || this.isInPolicyLevelOverpaymentAllocationFlow;
        this.policyNumber = params.policyNumber || this.policyNumber;
        this.distributedDocumentsResponseModel = params.distributedDocumentsResponseModel || this.distributedDocumentsResponseModel;
        this.authorizationAgreementParams = params.authorizationAgreementParams || this.authorizationAgreementParams;

        this.paperlessEmail = params.paperlessEmail || this.paperlessEmail;
        this.accountValidationVerificationCode = params.accountValidationVerificationCode ?? this.accountValidationVerificationCode;
        this.automaticPaymentsEnrolled = params.automaticPaymentsEnrolled || this.automaticPaymentsEnrolled;
        this.quickPayAutomaticPaymentsEnrolled = params.quickPayAutomaticPaymentsEnrolled || this.quickPayAutomaticPaymentsEnrolled;
        this.contextCallSuccess = params.contextCallSuccess || this.contextCallSuccess;
        this.contextCacheCallResponse = params.contextCacheCallResponse || this.contextCacheCallResponse;
    }

    get postPaymentResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.postPayment;
    }

    get savePayMethodResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.savePayMethod;
    }

    get nextBillResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.getNextBill;
    }

    get enrollInEzPayResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.enrollInEzPay;
    }

    get updatePayMethodResponse(): any {
        return this.submitPaymentResponses && this.submitPaymentResponses.updatePayMethod;
    }

    get flowInPlay(): boolean {
        return this.payBillForm != null;
    }

    getIsReinstatementEligible(): boolean {
        return this.isReinstatementEligible;
    }

    setIsReinstatementEligible(isReinstatementEligible: boolean): void {
        this.isReinstatementEligible = isReinstatementEligible;
    }

    get isRestrictedFlowType(): boolean {
        return !this.isQuickPay && this.isCyberLife || this.isReinstatementEligible || this.isNonOwner || this.isCollectionPayment || this.isSplitPartyUser;
    }

    clear(): void {
        this.payBillForm = undefined;
        this.considerThisDisplayable = undefined;
        this.emailAddress = undefined;
        this.paperlessEmail = undefined;
        this.selectedPayMethod = undefined;
        this.minimumPaymentOption = undefined;
        this.paymentDueDate = undefined;
        this.isReinstatementEligible = undefined;
        this.isEligibleForEasyPay = undefined;
        this.isWarnedPolicy = undefined;
        this.isPaperlessEligible = undefined;
        this.submitPaymentTimestamp = undefined;
        this.oipaPolicyPaymentCapAcheived = undefined;
        this.careMattersPolicyPaymentCapAcheived = undefined;
        this.nonOipaPolicyPaymentCapAcheived = undefined;
        this.nextDueDate = undefined;
        this.roleIdentifier = undefined;
        this.submitPaymentResponses = undefined;
        this.billingAccountsForRefund = undefined;

        this.paymentSuccessfullySubmitted = false;

        this.payMethodSaveAttempted = false;
        this.payMethodSaved = false;

        this.updatePayMethodAttempted = false;
        this.payMethodUpdated = false;

        this.ezPayEnrollmentAttempted = false;
        this.enrolledInEZPay = false;

        this.paperlessEnrollmentAttempted = false;
        this.savedPaperlessEsddaPrefs = false;
        this.enrolledInPaperless = false;

        this.billingAccountHolderName = undefined;
        this.producerNumbers = undefined;
        this.isOipaPolicy = false;
        this.isScheduledPayment = false;
        this.hasCareMatters = false;
        this.isNonOwner = false;
        this.isEligibleToSaveAsRefundMethod = undefined;
        this.displayCreditCardVulPolicyError = false;
        this.accountValidationVerificationCode = undefined;
        this.contextCallSuccess = false;
        this.contextCacheCallResponse = undefined;
    }
}

export interface NextBill {
    dueDate: Date | string;
    amountDue: number;
}
