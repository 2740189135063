import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionService } from '../session/session.service';
import { RoutingState } from './routing-state.service';
import { BrowserUtilsService, APICommonService, HeaderTypes } from '@nationwide/core-component-library-base';
import { AccountValidationVerificationCode, PersonalBillingService, PersonalBillingServiceToken } from '@nationwide/dgs-angular-billing-common';
import { ChangeDueDates } from '../../change-due-date/billing-due-dates.model';
import { Observable, catchError, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BillingApiCommonService {
    userAgent = window.navigator.userAgent;
    operatingSystem: string;

    // eslint-disable-next-line max-params
    constructor(private session: SessionService,
        private routingState: RoutingState,
        private browserUtilsService: BrowserUtilsService,
        private apiCommonService: APICommonService,
        @Inject(PersonalBillingServiceToken) private personalBillingService: PersonalBillingService
    ) {
        this.operatingSystem = this.browserUtilsService.populateOperatingSystem(this.userAgent);
    }

    buildHeader(headerValues: { appClientId: string; authToken: string; nwAppId: string; contentType: string; headerType: HeaderTypes; sessionId?: string }): HttpHeaders {
        return this.apiCommonService.buildHeader(headerValues);
    }

    generateTransactionId(): string {
        return this.apiCommonService.generateTransactionId();
    }

    fetchBillingChangeDueDates(): Observable<ChangeDueDates> {
        return this.personalBillingService.retrieveBillingPaymentDueDates({
            accountNumber: this.session.billingAccountNumber,
            accessToken: this.session.accessToken
        }).pipe(
            catchError(() => of(null))
        );
    }

    checkFieldForEmpty(field: any): any {
        return field;
    }

    pushAdditionalField({ ebiData, additionalFields, fieldName, value }: { ebiData: object; additionalFields: string[]; fieldName: string; value: any }): object {
        if (additionalFields.includes(fieldName)) {
            ebiData[fieldName] = value;
        }
        return ebiData;
    }

    convertBooleanToString(value: boolean): string {
        if (value) {
            return 'Yes';
        }
        return 'No';
    }

    isDefined(item: any): boolean {
        return item !== undefined && item !== null && typeof item !== 'undefined';
    }

    getConstantValue(valueIdentifier: string): string {
        for (const key of this.keys) {
            if (key.key === valueIdentifier) {
                if (key.key === 'ebiEndPoint') {
                    return key.value.ebiEndPoint;
                } else if (key.key === 'ctmEndPoint') {
                    return key.value.ctmEndPoint;
                } else {
                    return key.value;
                }
            }
        }
    }

    setPickerPageEBIData(ebiData: object, quickPayPickerEBIDataArray: QuickPayPickerEBIData[]): object {
        let index = 1;
        quickPayPickerEBIDataArray.forEach((quickPayPickerEBIData) => {
            ebiData[`BILLING_ACCOUNT_NUMBER${index}`] = quickPayPickerEBIData.agreementNumber;
            ebiData[`AGREEMENT_NUMBER${index}`] = quickPayPickerEBIData.agreementNumber;
            if (quickPayPickerEBIData.agreementLabel) {
                ebiData[`AGREEMENT_TYPE${index}`] = `${quickPayPickerEBIData.agreementLabel}-${quickPayPickerEBIData.agreementType}`;
            } else {
                ebiData[`AGREEMENT_TYPE${index}`] = quickPayPickerEBIData.agreementType;
            }
            index++;
        });
        return ebiData;
    }

    setEftAuthorizationFormEBIData(ebiData: object, additionalFields: string[], eftAuthorizationFormEbiData: EftAuthorizationFormEbiData): object {
        const ignoreToOverrideCommonFieldValues: string[] = ['DESCRIPTION', 'TEST_LEARN'];
        if (eftAuthorizationFormEbiData) {
            if (eftAuthorizationFormEbiData.eftAuthorizationFormElementsObject) {
                additionalFields.forEach((additionalField) => {
                    if (!ignoreToOverrideCommonFieldValues.includes(additionalField)) {
                        ebiData[additionalField] = eftAuthorizationFormEbiData.eftAuthorizationFormElementsObject[additionalField];
                    }
                });
            }
            if (eftAuthorizationFormEbiData.eftAuthorizationFormPolicyEbiData) {
                eftAuthorizationFormEbiData.eftAuthorizationFormPolicyEbiData.forEach((eftAuthorizationFormPolicyEbiData, index) => {
                    ebiData[`POLICY_NUMBER${index + 1}`] = eftAuthorizationFormPolicyEbiData.policyNumber;
                    ebiData[`COMPANY_ENGLISH${index + 1}`] = eftAuthorizationFormPolicyEbiData.companyName;
                    ebiData[`POLICYTYPE${index + 1}`] = eftAuthorizationFormPolicyEbiData.policyType;
                });
            }
        }
        return ebiData;
    }

    setBillingEbiData(
        { additionalFields, eventDescription, eventId, options }: { additionalFields: string[]; eventDescription: string; eventId: string; options: BillingEBIOptions }): {} {
        const paymentMethods = options.paymentMethod && options.paymentMethod[0] || <any>{}; // A HACK TO FIX SILENT EBI FAILURES
        const quickPayMethod = options.quickPayMethod || <any>{};
        this.mapCommonQuickPayEbiValues(quickPayMethod);
        const eftAuthorizationFormEbiData = options.eftAuthorizationFormEbiData || <any>{};
        const MAX_FIELD_LENGTH = 80;
        // truncate the string first to make sure any ampersands will cause an issue
        eventDescription = eventDescription.slice(0, MAX_FIELD_LENGTH);
        const AMPERSAND_ENTITY_LENGTH = 4;
        const descriptionLimit = MAX_FIELD_LENGTH - (eventDescription.match(/&/g) || []).length * AMPERSAND_ENTITY_LENGTH;
        let ebiData = {};
        let accountName = 'Allied';

        accountName = this.checkFieldForEmpty(accountName);
        if (options.documentType === 'Make Payment') {
            ebiData = {
                DESCRIPTION: eventDescription.slice(0, descriptionLimit),
                ECN: this.session.ecn,
                TEST_LEARN: 'B',
                USER_ACCESS_LVL: 'View'
            };
        } else if (options.isGlobalNavOption) {
            ebiData = {
                CURRENT_URL: this.routingState.getCurrentUrl(),
                DESCRIPTION: eventDescription.slice(0, descriptionLimit),
                ECN: this.session.ecn || 'Unknown',
                FULLPOLICYNB: this.session.billingAccountNumber || options.IDENTIFIER || 'NA',
                GUID: this.session.guid || 'Unknown',
                IP_ADDRESS: this.session.ipAddress || 'Unknown',
                JSESSION_ID: this.session.a2aSessionId || 'Unknown',
                SOURCE_SYSTEM: this.session.sourceSystem || options.sourceSystem || 'NA',
                STATE: options.state || this.session.state || 'XX',
                TEST_LEARN: 'B',
                USER_ACCESS_LVL: 'View',
                USER_ID: this.session.userId || 'Unknown'
            };
        } else {
            const browserType = this.browserUtilsService.populateBrowserType(this.userAgent);

            ebiData = {
                BROWSER_TYPE: browserType,
                BROWSER_VERSION: this.browserUtilsService.populateBrowserVersion(this.userAgent, browserType),
                DESCRIPTION: eventDescription.slice(0, descriptionLimit),
                ECN: this.session.ecn,
                OS_TYPE: this.operatingSystem,
                OS_VERSION: this.browserUtilsService.populateDeviceAndOSVersion(this.userAgent, this.operatingSystem)['osVersion'],
                TEST_LEARN: 'B',
                USER_ACCESS_LVL: 'View'
            };
        }

        if (this.isDefined(additionalFields) && additionalFields.length > 0) {
            const fullPolicyNB = this.session.billingAccountNumber || paymentMethods.agreementNumber || quickPayMethod.policyNumber || options.IDENTIFIER || 'NA';
            const pageURL = this.routingState.getCurrentUrl();
            const sourceSystem = this.session.sourceSystem || paymentMethods.sourcesystemEbi || quickPayMethod.sourceSystem || options.sourceSystem || 'NA';
            const previousURL = this.routingState.getPreviousUrl() || 'unknown';
            const identifier = options.IDENTIFIER || this.session.billingAccountNumber || this.session.policyNumber || 'NA';
            const ipAddress = this.session.ipAddress || 'unknown';
            const oipaProductType = this.session.lifeProductType || 'NA';
            const productLine = quickPayMethod.productLine || 'P';
            const jSessionId = this.session.a2aSessionId || 'unknown';
            const userId = this.session.userId || quickPayMethod.userId;
            const deviceType = this.browserUtilsService.populateDeviceAndOSVersion(this.userAgent, this.operatingSystem)['deviceType'];
            const guid = this.session.guid || quickPayMethod.guid;
            const statementType = options.documentType;
            const viewBill = 'view';
            const accountType = paymentMethods.accountType;
            const confirmationNumber = paymentMethods.confirmationNumber;
            const easyPay = paymentMethods.easyPay ? 'T' : 'F';
            const firstName = options.firstName || paymentMethods.firstName || this.session.firstName;
            const lastName = options.lastName || paymentMethods.lastName || this.session.lastName;
            const sourceSystemCL = 'CL';
            const sourceSystemOipa = 'NBP/LIFE';
            const paperless = paymentMethods.paperless ? 'T' : 'F';
            const paymentDate = paymentMethods.paymentDate || options.paymentDate || 'NA';
            const sessionPolicyNb = this.session.policyNumber || 'NA';
            const agreementStatus = options.agreementStatus || paymentMethods.agreementStatus || 'NA';
            const state = options.state || paymentMethods.stateEbi || this.session.state || 'XX';
            const responseCode = options.responseCode;
            const responseStatus = options.responseStatus;
            const clientType = paymentMethods.clientType || quickPayMethod.clientType;
            const phoneNumber = quickPayMethod.phoneNumber;
            const creditCardVendor = paymentMethods.creditCardVendor;
            const defaultMethod = paymentMethods.defaultMethod;
            const nextPaymentDate = options.isCollectionPayment ? 'NA' : paymentMethods.nextDueDate;
            const nextPaymentAmount = options.isCollectionPayment ? 'NA' : paymentMethods.nextPaymentAmount;
            const payNowPayLater = paymentMethods.payNowPayLater;
            const bankName = paymentMethods.bankName;
            const ecn = this.session.ecn || quickPayMethod.ecn || 'Unknown';
            const yesNoSelection = options.yesNoSelection || '';
            const dob = quickPayMethod.dob;
            const pickerPageSelectedPaymentAmount = quickPayMethod.pickerPageSelectedPaymentAmount;
            const updatedDueDate = options.updatedDueDate;
            const plaid = options.plaid ? 'Yes' : 'No';
            const destinationURL = options.destinationURL || 'Unknown';
            const paymentPlan = options.paymentPlan;
            const paymentMethodType = options.paymentMethodType;

            let sourceSystemPayBill;
            if (options.isCyberLifeOption) {
                sourceSystemPayBill = sourceSystemCL;
            } else if (options.isOipaOption) {
                sourceSystemPayBill = this.session.isSAP ? 'SAP Billing' : sourceSystemOipa;
            } else {
                sourceSystemPayBill = paymentMethods.sourcesystemEbi || options.sourceSystem || sourceSystem || 'NA';
            }

            let paymentOption;
            if (options.isCollectionPayment) {
                paymentOption = 'Collections';
            } else {
                switch (paymentMethods.paymentChangeType) {
                    case 'minimum-payment': {
                        paymentOption = 'Monthly';

                        break;
                    }
                    case 'partial-payment': {
                        paymentOption = 'FLEX';

                        break;
                    }
                    case 'easy-payment': {
                        paymentOption = 'EasyPay';

                        break;
                    }
                    case 'full-payment': {
                        paymentOption = 'FullPay';

                        break;
                    }
                    case 'flex-payment': {
                        paymentOption = 'FlexPay';

                        break;
                    }
                    // No default
                }
            }

            const paymentAmount = paymentMethods.paymentAmount || options.paymentAmount || 'NA';
            const paymentType = paymentMethods.paymentType || this.parseAccountType(paymentMethods.accountType);
            const browserType = this.browserUtilsService.populateBrowserType(this.userAgent);
            const browserVersion = this.browserUtilsService.populateBrowserVersion(this.userAgent, browserType);
            const currentChangeType = options.currentChangeType || 'NA';

            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ebiData', value: '' });
            switch (eventId) {
                case environment.EBI_EVENT_ID.BILLING_DETAILS_ID: {
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });

                    break;
                }
                case environment.EBI_EVENT_ID.DEVICE_DETAILS_ID: {
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAGE_URL', value: pageURL });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });

                    break;
                }
                case environment.EBI_EVENT_ID.ON_SMART_MILES_LINK_CLICK: {
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAGE_URL', value: pageURL });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                    delete ebiData['BROWSER_TYPE'];
                    delete ebiData['BROWSER_VERSION'];
                    delete ebiData['OS_TYPE'];
                    delete ebiData['OS_VERSION'];
                    delete ebiData['TEST_LEARN'];

                    break;
                }
                case environment.EBI_EVENT_ID.QUESTIONS_AND_TIPS_ID: {
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });

                    break;
                }
                default: if (eventId === environment.EBI_EVENT_ID.VIEW_PDF_STATEMENT_ID && identifier) {
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'VIEW_BILL', value: viewBill });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATEMENT_TYPE', value: statementType });
                } else if (eventId === environment.EBI_EVENT_ID.LOAD_PDF_STATEMENT_ID && identifier) {
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATEMENT_TYPE', value: statementType });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                } else if (eventId === environment.EBI_EVENT_ID.PRINTER_LINK_CLICKED_ID) {
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                    ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRINT', value: 'P' });
                } else if (options.isCyberLifeOption) {
                    switch (eventId) {
                        case environment.EBI_EVENT_ID.ON_LOAD_MAKE_PAYMENT_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystemCL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_VERSION', value: browserVersion });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_PAYMENT_CAP_ERROR: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystemCL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AGREEMENT_STATUS', value: agreementStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNTNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_AMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_TYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: sessionPolicyNb });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });

                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_CONTINUE_MAKE_PAYMENT_PAGE: {
                            const paybillIdentifier = options.isReinstatementPayment ? this.session.policyNumber : identifier;
                            const paymentOpt = options.isReinstatementPayment ? 'REINSTATEMENT' : paymentOption;
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'EASY_PAY', value: easyPay });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEFAULT_METHOD', value: defaultMethod });

                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_OPTION', value: paymentOpt });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTAMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: paybillIdentifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PLAID', value: plaid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_VERSION', value: browserVersion });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_PAYMENT_REVIEW_PAGE: {
                            const billingIdentifier = options.isCollectionPayment || options.isReinstatementPayment ? this.session.policyNumber : identifier;
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AUTOMATIC_PAYMENT_SETUP', value: easyPay });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTAMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: billingIdentifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PRINT_LINK_CONFIRMATION_CLICKED: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystemCL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRINT', value: 'P' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LAND_CONFIRMATION_PAGE_OF_THE_MAKE_A_PAYMENT_FLOW: {
                            const billingIdentifier = options.isCollectionPayment || options.isReinstatementPayment ? this.session.policyNumber : identifier;
                            const fullPolicyNo = options.isCollectionPayment ? this.session.policyNumber : fullPolicyNB;
                            const srcSystem = options.isCollectionPayment ? options.sourceSystem || sourceSystem : sourceSystemPayBill;

                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AGREEMENT_STATUS', value: agreementStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNo });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTAMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: billingIdentifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_CONFIRMATION_NUMBER', value: confirmationNumber });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXT_PAYMENT_DATE', value: nextPaymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXT_PAYMENT_AMOUNT', value: nextPaymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: srcSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_TYPE', value: oipaProductType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PLAID', value: plaid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'Bank_Verification_Code', value: options.accountValidationVerificationCode || 'NA' });

                            break;
                        }
                        // No default
                    }
                } else {
                    switch (eventId) {
                        case environment.EBI_EVENT_ID.ON_LAND_CONFIRMATION_PAGE_OF_THE_MAKE_A_PAYMENT_FLOW: {
                            const billingIdentifier = paymentMethods.agreementStatus === 'Warned' && clientType === 'authenticated' || options.isCollectionPayment || options.isReinstatementPayment ? this.session.policyNumber : identifier;
                            const fullPolicyNo = paymentMethods.agreementStatus === 'Warned' && clientType === 'authenticated' || options.isCollectionPayment ? this.session.policyNumber : fullPolicyNB;
                            const srcSystem = options.isCollectionPayment ? options.sourceSystem || sourceSystem : sourceSystemPayBill;

                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AGREEMENT_STATUS', value: agreementStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNo });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTAMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: billingIdentifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_CONFIRMATION_NUMBER', value: confirmationNumber });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXT_PAYMENT_DATE', value: nextPaymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXT_PAYMENT_AMOUNT', value: nextPaymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: srcSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_TYPE', value: oipaProductType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PLAID', value: plaid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'Bank_Verification_Code', value: options.accountValidationVerificationCode || 'NA' });
                            break;
                        }
                        case environment.EBI_EVENT_ID.PRINT_LINK_CONFIRMATION_CLICKED: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AGREEMENT_STATUS', value: options.agreementStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNTNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRINT', value: 'P' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_MAKE_PAYMENT_BTN: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_CONTINUE_MAKE_PAYMENT_PAGE: {
                            const paybillIdentifier = options.isReinstatementPayment ? this.session.policyNumber : identifier;
                            const paymentOpt = options.isReinstatementPayment ? 'REINSTATEMENT' : paymentOption;
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'EASY_PAY', value: easyPay });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_OPTION', value: paymentOpt });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTAMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });

                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEFAULT_METHOD', value: defaultMethod });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: paybillIdentifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PLAID', value: plaid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_VERSION', value: browserVersion });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_PAYMENT_REVIEW_PAGE: {
                            const billingIdentifier = options.isCollectionPayment ? this.session.policyNumber : identifier;

                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AUTOMATIC_PAYMENT_SETUP', value: easyPay });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTAMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: billingIdentifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEFAULT_METHOD', value: defaultMethod });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXT_PAYMENT_DATE', value: nextPaymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXT_PAYMENT_AMOUNT', value: nextPaymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYNOW_PAYLATER', value: payNowPayLater });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_MAKE_PAYMENT_PAGE: {
                            const paybillIdentifier = options.isCollectionPayment || options.isReinstatementPayment ? this.session.policyNumber : identifier;

                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystemPayBill });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: paybillIdentifier });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_PAYMENT_CAP_ERROR: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AGREEMENT_STATUS', value: options.agreementStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNTNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_AMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_TYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: sessionPolicyNb });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystemPayBill });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });

                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_PAYMENT_AMOUNT_NOTIFICATION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AGREEMENT_STATUS', value: options.agreementStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystemPayBill });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            // The EBI event does not have display fields for these
                            // two properties, in ICP or ISB, for only this event
                            delete ebiData['DESCRIPTION'];
                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.BILLING_TIMELINE_LOAD: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });

                            break;
                        }
                        case environment.EBI_EVENT_ID.LOG_NUMBER_OF_USERS_COMING_IN_BILLING_VIA_THE_BILLING_TIMELINE_URL: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_MAKE_PAYMENT_PAGE_WITH_PMOF: {
                            const paybillIdentifier = options.isCollectionPayment || options.isReinstatementPayment ? this.session.policyNumber : identifier;

                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: paybillIdentifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_PAYMENT_CONFIRMATION_PAGE_WITH_SCHEDULED_FUTURE_PAYMENT: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTAMOUNT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AGREEMENT_STATUS', value: agreementStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB ', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_CONFIRMATION_NUMBER', value: confirmationNumber });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PAYMENT_PREFERENCES_PAGE_LOAD_NO_SAVED_PAYMENT_METHODS_ID:
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_EDIT_PAYMENT_METHOD_REVIEW_PAGE:
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PAYMENT_PREFERENCES_PAGE_LOAD_SAVED_PAYMENT_METHODS_ID: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_OPEN_TIMELINE_EVENTS_MODAL: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_OPEN_TIMELINE_REFUNDS_MODAL: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_ADD_PAYMENT_METHOD_LANDING_ON_CONTINUE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEFAULT_METHOD', value: defaultMethod });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_ADD_PAYMENT_METHOD_ON_REVIEW_LOAD: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_ADD_PAYMENT_REVIEW_ON_CLICK_SUBMIT: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_ADD_PAYMENT_METHOD_CONFIRMATION_LOAD: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_ADD_PAYMENT_CONFIRMATION_PRINT:
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PRINT_BUTTON: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRINT', value: 'P' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_ADD_PAYMENT_CONFIRMATION_HARDFALL: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CHANGE_TYPE', value: 'Unknown' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'QUOTE_ID', value: 'NA' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_REMOVE_NON_DEFAULT_PAYMENT_METHOD_CONFIRMATION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_REMOVE_DEFAULT_PAYMENT_METHOD_CONFIRMATION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_REMOVE_PAYMENT_METHOD_CONFIRMATION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });

                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_RECURRING_PATMENT_BTN: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_EDIT_AUTOMATIC_PAYMENTS_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXTPAYMENT', value: nextPaymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SCHEDULED_DATE', value: paymentDate });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_AUTOMATIC_PAYMENTS_LANDING_PAGE:
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_SUBMIT_BTN_EDIT_AUTOMATIC_PAYMENTS_REVIEW_PAGE:
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEFAULT_METHOD', value: defaultMethod });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE_AUTH_AGREEMENT: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_AUTHORIZATION_AGREEMENT_LINK: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'AUTH_AGREE', value: options.authAgreementUrl });
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_SUCCESS_OR_FAIL_EASYPAY_ENROLLMENT: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_COMPLETION_OF_EASYPAY_ENROLLMENT: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'MONTHLY_PAYMENT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_CANCEL_IN_SUSPEND_EASY_PAY_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'MONTHLY_PAYMENT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'MONTHLY_PAYMENT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_PRINT_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'MONTHLY_PAYMENT', value: paymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_PAY_LATER_OR_MAP_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYNOW_PAYLATER', value: payNowPayLater });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSIONID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_PRINT_BTN_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRINT', value: 'P' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_EDIT_OF_PAYMENT_METHOD_ON_AUTOMATIC_PAYMENT_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DRAFT_NOTIFICATION', value: 'NA' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_PRINT_BTN_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRINT', value: 'P' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_PRINT_BTN_CANCEL_EASY_PAY_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRINT', value: 'P' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_QUICK_PAY_PICKER_PAGE: {
                            if (quickPayMethod.quickPayPickerEBIData) {
                                ebiData = this.setPickerPageEBIData(ebiData, quickPayMethod.quickPayPickerEBIData);
                            }
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PHONENB', value: phoneNumber });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_CONTINUE_OF_QUICK_PAY_PICKER_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: 'Unauthenticated' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DOB', value: dob });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FULLPOLICYNB', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_AMOUNT', value: pickerPageSelectedPaymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PHONENB', value: phoneNumber });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: pageURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_QUICK_PAY_SEARCH_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'NEXTPAYMENT', value: nextPaymentAmount });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SCHEDULED_DATE', value: 'NA' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_CONFIRMATION_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_OF_EDIT_PAYMENT_METHOD_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_SUBMIT_EDIT_PAYMENT_METHOD_REVIEW_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAPERLESS', value: paperless });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNTTYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BANKNAME', value: bankName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CREDITCARDVENDOR', value: creditCardVendor });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEFAULT_METHOD', value: defaultMethod });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENTTYPE', value: paymentType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_SUBMIT_CANCEL_AUTOMATIC_PAYMENTS_LANDING: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CANCEL', value: yesNoSelection });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SCHEDULED_DATE', value: 'NA' });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAYMENT_PLAN', value: paymentPlan });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_CONFIRMATION_PAGE_CANCEL_SCHEDULED_PAYMENT: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IP_ADDRESS', value: ipAddress });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSIONID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'METHOD_TYPE', value: options.methodType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAY_PLAN_TYPE', value: options.payplan });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: options.responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: options.responseStatus });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_CONTINUE_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CURRENT_URL', value: this.routingState.getCurrentUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_INCOMPLETE_FORM_ELEMENTS_IN_EFT_AUTH_FORM: {
                            if (eftAuthorizationFormEbiData) {
                                ebiData = this.setEftAuthorizationFormEBIData(ebiData, additionalFields, eftAuthorizationFormEbiData);
                            }

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_SUBMIT_BTN_REMOVE_REFUND_METHOD_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.LOG_NUMBER_OF_TIMES_HP_EXTREME_SERVICE_INVOKING: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSIONID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CONFIRMATION_LOAD_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CURRENT_CHANGE_TYPE', value: currentChangeType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CURRENT_URL', value: pageURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_ADD_REFUND_METHOD_BTN: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.PP_ADD_REFUND_METHOD: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ACCOUNT_TYPE', value: accountType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_TYPE'];
                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['USER_ACCESS_LVL'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_CHANGE_DUE_DATE_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['BROWSER_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_BANK_ACCOUNT_VERIFICATION_ERROR: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'Bank_Verification_Code', value: options.accountValidationVerificationCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CLIENT_TYPE', value: clientType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CURRENT_URL', value: this.routingState.getCurrentUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'IDENTIFIER', value: identifier });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_GLOBAL_NAV_BILLS_CHILD_OPTION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DESTINATION_URL', value: destinationURL });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_GLOBAL_NAV_POLICIES_CHILD_OPTION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DESTINATION_URL', value: destinationURL });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_GLOBAL_NAV_BILLS_OPTION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_CHANGE_DUE_DATE_CONFIRMATION_LANDING_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_CODE', value: responseCode });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'RESPONSE_STATUS', value: responseStatus });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'UPDATED_DUE_DATE', value: updatedDueDate });

                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_CLICK_OF_CONTACT_US_LINK_LOGS_OUT_OF_ISB: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'CURRENT_URL', value: this.routingState.getCurrentUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'FIRST_NAME', value: firstName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LAST_NAME', value: lastName });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PRODUCT_LINE', value: productLine });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'LOB', value: 'Billing Account' });

                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];
                            delete ebiData['DESCRIPTION'];
                            delete ebiData['TEST_LEARN'];

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_SELECT_OF_THE_CONTACT_US_BUTTON_IN_THE_PAY_PLAN_SECTION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_VERSION', value: browserVersion });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'METHOD_TYPE', value: paymentMethodType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAY_PLAN_TYPE', value: paymentPlan });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_SELECT_OF_THE_EDIT_PAYMENT_PLAN_BUTTON: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_VERSION', value: browserVersion });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'METHOD_TYPE', value: paymentMethodType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAY_PLAN_TYPE', value: paymentPlan });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_DISPLAY_OF_THE_PAYMENT_PLAN_SECTION: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_TYPE', value: browserType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BROWSER_VERSION', value: browserVersion });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'ECN', value: ecn });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'METHOD_TYPE', value: paymentMethodType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PAY_PLAN_TYPE', value: paymentPlan });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: this.routingState.getPreviousUrl() });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'SOURCE_SYSTEM', value: sourceSystem });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            break;
                        }
                        case environment.EBI_EVENT_ID.ON_LOAD_CHANGE_DUE_DATE_REVIEW_AND_SUBMIT_PAGE: {
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'BILLING_ACCOUNT_NUMBER', value: fullPolicyNB });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'DEVICE_TYPE', value: deviceType });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'GUID', value: guid });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'JSESSION_ID', value: jSessionId });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'PREVIOUS_URL', value: previousURL });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'STATE', value: state });
                            ebiData = this.pushAdditionalField({ ebiData, additionalFields, fieldName: 'USER_ID', value: userId });

                            delete ebiData['BROWSER_VERSION'];
                            delete ebiData['OS_TYPE'];
                            delete ebiData['OS_VERSION'];

                            break;
                        }
                        // No default
                    }
                }
            }

            return ebiData;
        } else {
            return ebiData;
        }
    }

    get keys(): { key: string; value: any }[] {
        const keys = [];
        keys.push({ key: 'ebiEndPoint', value: environment.EBI });
        keys.push({ key: 'ctmEndPoint', value: environment.CTM });
        keys.push({ key: 'client_id', value: environment.CLIENT_ID });
        keys.push({ key: 'appCookie', value: environment.APP_COOKIE });
        keys.push({ key: 'sessionId', value: this.session.a2aSessionId });
        keys.push({ key: 'nw_APP_ID', value: environment.NW_APP_ID });
        keys.push({ key: 'content_Type', value: 'application/json' });
        keys.push({ key: 'transaction_Id', value: environment.TRANSACTION_ID });
        return keys;
    }

    parseAccountType(accountType: string): 'EFT' | 'BankCard' | 'Masterpass' {
        let output;

        if (accountType === 'Bank Account' || accountType === 'Checking' || accountType === 'Savings') {
            output = 'EFT';
        } else if (accountType === 'Masterpass') {
            output = accountType;
        } else {
            output = 'BankCard';
        }

        return output;
    }

    mapCommonQuickPayEbiValues(quickPayMethod: QuickPayEBIData): void {
        if (this.session.quickPayCommonEbiValues) {
            quickPayMethod.ecn = this.session.quickPayCommonEbiValues.ecn ?? 'Unknown';
            quickPayMethod.guid = this.session.quickPayCommonEbiValues.guid ?? 'NA';
            quickPayMethod.userId = 'NA';
        } else if (quickPayMethod) {
            quickPayMethod.ecn = quickPayMethod.ecn ?? 'Unknown';
            quickPayMethod.guid = quickPayMethod.guid ?? 'NA';
            quickPayMethod.userId = 'NA';
        }
    }
}

export interface EftAuthorizationFormEbiData {
    eftAuthorizationFormPolicyEbiData?: EftAuthorizationFormPolicyEbiData[];
    eftAuthorizationFormElementsObject?: object;
}

export interface EftAuthorizationFormPolicyEbiData {
    policyNumber?: string;
    companyName?: string;
    policyType?: string;
}

export interface PaymentMethodsData {
    firstName: string;
    lastName: string;
    easyPay: boolean;
    paperless: boolean;
    paymentAmount: string;
    paymentDate: string;
    paymentType: string;
    paymentChangeType: string;
    accountType: string;
    agreementStatus: string;
    agreementNumber: string;
    clientType: string;
    defaultMethod: string;
    creditCardVendor: string;
    nextDueDate: string;
    nextPaymentAmount: string;
    payNowPayLater: string;
    bankName: string;
    creditCardType: string;
    sourcesystemEbi: string;
    stateEbi: string;
    had417Error: string;
}

export interface QuickPayEBIData {
    description?: string;
    age?: number;
    clientType?: string;
    dob?: string;
    accountSize?: number;
    responseCode?: number;
    responseStatus?: string;
    zipCode?: string;
    stateOfIssue?: string;
    policyNumber?: string;
    phoneNumber?: string;
    ecn?: string;
    quickPayPickerEBIData?: QuickPayPickerEBIData[];
    pickerPageSelectedPaymentAmount?: string;
    productLine?: string;
    sourceSystem?: string;
    userId?: string;
    guid?: string;
}

export interface QuickPayPickerEBIData {
    agreementNumber: string;
    agreementLabel?: string;
    agreementType?: string;
}

export interface BillingEBIOptions {
    documentType?: string;
    paymentAmount?: string;
    paymentDate?: string;
    quickPayMethod?: QuickPayEBIData;
    paymentMethod?: PaymentMethodsData[];
    sourceSystem?: string;
    agreementStatus?: string;
    state?: string;
    firstName?: string;
    lastName?: string;
    IDENTIFIER?: string;
    callback?: () => void;
    isCyberLifeOption?: boolean;
    responseCode?: string;
    responseStatus?: 'ERROR' | 'SUCCESS';
    yesNoSelection?: 'Yes' | 'No';
    isOipaOption?: boolean;
    plaid?: boolean;
    eftAuthorizationFormEbiData?: EftAuthorizationFormEbiData;
    currentChangeType?: 'Add' | 'Edit';
    accountValidationVerificationCode?: AccountValidationVerificationCode | 'NA';
    isGlobalNavOption?: boolean;
    destinationURL?: string;
    updatedDueDate?: string;
    clientType?: string;
    confirmationNumber?: string;
    policyNumber?: string;
    paymentPlan?: string;
    paymentMethodType?: string;
    authAgreementUrl?: string;
    ecn?: string;
    isCollectionPayment?: boolean;
    isReinstatementPayment?: boolean;
    methodType?: string;
    payplan?: string;
}
